import API_URL from 'src/api/apiConstants';
import { DATE_FORMAT } from 'src/utils/constants';
import { getFormattedDate } from 'src/utils/getInitials';
import { reportFormConfig } from './reportFormModel';
import { sensorTransactionReportFormConfig } from '../sensorTransactions/sensorTransactionReportFormModel';
import { breakdownReportFormConfig } from '../breakdown/breakdownReportFormModel';
import { vehicleUtilizationReportFormConfig } from '../otherReports/vehicleUtilizationReportFormModel';
import { vehicleSummaryReportFormConfig } from '../otherReports/vehicleSummaryReportFormModel';

// eslint-disable-next-line import/prefer-default-export
export const getReportConfig = (reportName) => {
  const defaultConfig = {
    filterOptions: {},
    viewReport: true,
    downloadUrl: API_URL[reportName],
    dateConfig: { onlyDate: false, dateFormat: null },
    formConfig: reportFormConfig
  };
  let reportConfig = { ...defaultConfig };
  switch (reportName) {
    case 'vehicleSummaryReport':
      reportConfig = {
        ...defaultConfig,
        filterOptions: { wailonVehicle: true },
        dateConfig: { onlyDate: true },
        formConfig: vehicleSummaryReportFormConfig
      };
      break;
    case 'vehicleUtilizationReport':
      reportConfig = {
        ...defaultConfig,
        viewReport: false,
        filterOptions: { assetType: true },
        dateConfig: { onlyDate: true, views: ['month', 'year'], openTo: 'month' },
        formConfig: vehicleUtilizationReportFormConfig
      };
      break;
    case 'sensorTransactionReport':
      reportConfig = {
        ...defaultConfig,
        filterOptions: { vehicle: true },
        dateConfig: { dateFormat: DATE_FORMAT.timeStamp },
        formConfig: sensorTransactionReportFormConfig
      };
      break;
    case 'vehicleGroupWiseReport':
      reportConfig = {
        ...defaultConfig,
        viewReport: false,
        filterOptions: { assetType: true },
        dateConfig: { dateFormat: DATE_FORMAT.timeStamp },
      };
      break;
    case 'groupWiseComparisonReport':
      reportConfig = {
        ...defaultConfig,
        viewReport: false,
        formConfig: vehicleUtilizationReportFormConfig
      };
      break;
    case 'breakdownReport':
      reportConfig = {
        ...defaultConfig,
        filterOptions: { vehicle: true, assetType: true },
        dateConfig: { dateFormat: DATE_FORMAT.timeStamp },
        formConfig: breakdownReportFormConfig
      };
      break;
      // case 'milageReport:
      //   reportConfig = {
      //     ...defaultConfig,
      //     filterOptions: { ...REPORT_FILTER_OPTIONS, dispenser: false },
      //   };
      //   break;
    default:
      break;
  }
  return reportConfig;
};

export const getFormattedReportURL = (url, reportName, formData, startTime, endTime) => {
  let formPayload = { ...formData };
  let reportUrl = `${url}&page=1&page_size=50`;
  if (reportName === 'vehicleSummaryReport') {
    reportUrl = url.replace('{vehicleId}', formData.vehicle_id);
    formPayload = {
      [startTime.name]: getFormattedDate(formData[startTime.name], 'DD-MM-YYYY'),
      [endTime.name]: getFormattedDate(formData[endTime.name], 'DD-MM-YYYY')
    };
  } else if (reportName === 'vehicleUtilizationReport') {
    const formattedDate = getFormattedDate(formPayload[startTime.name], 'MMM,MM,YYYY');
    const dateArray = formattedDate.split(',');
    formPayload = {
      asset_type: formData.asset_type, month: dateArray[1], year: dateArray[2], month_text: dateArray[0]
    };
    delete formPayload[startTime.name];
  } else if (reportName === 'groupWiseComparisonReport') {
    formPayload = { date: getFormattedDate(formPayload[startTime.name], 'DD-MM-YYYY:HH-mm') };
    delete formPayload[startTime.name];
  }
  const keyList = Object.keys(formPayload);
  let queryParams = '';
  keyList.forEach((key) => {
    if (formPayload[key] && formPayload[key] !== 'all') {
      queryParams += `&${key}=${formPayload[key]}`;
    }
  });
  const apiURL = reportUrl + queryParams;
  return apiURL;
};
