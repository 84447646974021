import React from 'react';
import {
  Container, Typography, Box, Paper, List, ListItem
} from '@mui/material';

export default function PrivacyPolicy() {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 4, marginY: 4, borderRadius: 2 }}>
        <Typography
          variant="h3"
          align="center"
          sx={{
            color: 'white', backgroundColor: '#3b9de1e6', padding: 2, borderRadius: 1
          }}
          gutterBottom
        >
          Privacy Policy
        </Typography>

        <Typography variant="h5" align="center" color="textSecondary" gutterBottom>
          Effective Date: 01/01/2025
        </Typography>

        <Typography variant="body1" sx={{ pt: 2 }} paragraph>
          <strong>Callcomm FMS</strong>
          {' '}
          (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) values your privacy. This Privacy Policy outlines how we collect, use,
          and protect your information when you use our fuel management mobile application (the &quot;App&quot;).
        </Typography>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, pt: 2 }} paragraph>
            We may collect the following types of information:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1">
                <strong>Personal Information:</strong>
                {' '}
                Name, email address, phone number, and other identifiable details.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                <strong>Device Information:</strong>
                {' '}
                Device type, operating system, IP address, and unique device identifiers.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                <strong>Usage Data:</strong>
                {' '}
                Information about how you interact with the App, including logs and preferences.
              </Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h4" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1">Provide and maintain the App&apos;s functionality.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">Monitor usage patterns to improve user experience.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">Notify you of updates, new features, or technical issues and bug fixes.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">Ensure data security, privacy and prevent unauthorized access.</Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
            3. Sharing of Information
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, mb: 1 }} paragraph>
            We do not sell your personal data. However, we may share information with:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1">Service providers who assist in App functionality (e.g., analytics providers).</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">Legal authorities, if required to comply with the law.</Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
            4. Data Security
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, pb: 2 }} paragraph>
            We implement industry-standard security measures to protect your data. However, no system is entirely secure, and we encourage you to take precautions when sharing personal information.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h4" gutterBottom>
            5. Your Rights
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1">Access, update, or delete your personal information.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">Opt out of data collection where applicable.</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">Withdraw consent for location tracking at any time.</Typography>
            </ListItem>
          </List>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
            6. Third-Party Services
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, pb: 2 }} paragraph>
            The App may integrate with third-party services (e.g., cloud platforms). Please review their privacy policies as we are not responsible for their practices.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
            7. Changes to this Policy
          </Typography>
          <Typography variant="body1" sx={{ pl: 2, pb: 2 }} paragraph>
            We may update this Privacy Policy periodically. Any changes will be notified through the App or via email.
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2, paddingBottom: 2 }}>
          <Typography variant="h4" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography variant="body1" sx={{ pl: 2 }}>If you have any questions or concerns, please contact us:</Typography>
          <List>
            <ListItem>
              <Typography variant="body1">
                <strong>Email:</strong>
                {' '}
                <a href="mailto:sanjayjain@callcomm.org" rel="noreferrer">
                  sanjayjain@callcomm.org
                </a>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                <strong>Phone:</strong>
                {' '}
                <a href="tel:+91-9989094607">+91-9989094607</a>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                <strong>Address:</strong>
                {' '}
                Plot #99, Flat #8, Ashish Apartment, East Marredpally, Secunderabad, Telangana, India  - 500026
              </Typography>
            </ListItem>
          </List>
        </Box>
      </Paper>
    </Container>
  );
}
