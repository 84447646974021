import * as Yup from 'yup';

// report form model
export const vehicleSummaryReportFormModel = {
  formId: 'vehicleSummaryReportConfig',
  formField: {
    wailonVehicleNumber: {
      name: 'vehicle_id',
      label: 'Select Vehicle',
      type: 'select',
      errorMsg: 'Vehicle selection required!',
      data: []
    },
    startTime: {
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      errorMsg: 'Start time is required!'
    },
    endTime: {
      name: 'end_date',
      label: 'End Date',
      type: 'date',
      errorMsg: 'End time is required!'
    }
  }
};

// Initial values
const {
  formField: {
    wailonVehicleNumber,
    startTime,
    endTime
  }
} = vehicleSummaryReportFormModel;

export const vehicleSummaryReportFormInitialValues = {
  [wailonVehicleNumber.name]: undefined,
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const vehicleSummaryReportValidationSchema = Yup.object().shape({
  [wailonVehicleNumber.name]: Yup.string().required(wailonVehicleNumber.errorMsg),
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(endTime.errorMsg)
});

export const vehicleSummaryReportFormConfig = {
  formModel: vehicleSummaryReportFormModel,
  initialValues: vehicleSummaryReportFormInitialValues,
  validation: vehicleSummaryReportValidationSchema,
};
